import React from 'react';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa6';
import { FaMapMarkerAlt } from 'react-icons/fa';

import { images } from '../../constants';
import './Footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <div className='app__footer section__padding' id='footer'>
      {/* <FooterOverlay />
    <Newsletter /> */}

      <div className='app__footer-links'>
        <div className='app__footer-links_contact'>
          <h1 className='app__footer-headtext'>{t('contact_us')}</h1>
          <p className='p__opensans'>
            <FaMapMarkerAlt /> Aleksanterinkatu 22 C, 33100 Tampere
          </p>
          <p className='p__opensans'>
            <MdEmail /> baglungdurbar@gmail.com
          </p>
          <p className='p__opensans'>
            <FaPhone /> +358 44 247 8427
          </p>
        </div>

        <div className='app__footer-links_logo'>
          <img src={images.logo_main} alt='footer_logo' />
          <p className='p__opensans'>&quot;{t('contact_desc')}.&quot;</p>
          <img
            src={images.spoon}
            alt='spoon'
            className='spoon__img'
            style={{ marginTop: 15 }}
          />
          <div className='app__footer-links_icons'>
            <a
              target='_blank'
              href='https://www.facebook.com/profile.php?id=61558930258708'
              rel='noreferrer'
            >
              <FiFacebook />
            </a>
            <a
              target='_blank'
              href='https://www.instagram.com/baglungdurbar/'
              rel='noreferrer'
            >
              <FiInstagram />
            </a>
          </div>
        </div>

        <div className='app__footer-links_work'>
          <h1 className='app__footer-headtext'>{t('opening_hours')}</h1>
          <p className='p__opensans'>{t('monday')} 10:30 - 14:30</p>
          <p className='p__opensans'>{t('tuethurs')} 10:30 - 21:00</p>
          <p className='p__opensans'>{t('friday')} 10:30 - 23:00</p>
          <p className='p__opensans'>{t('saturday')} 11:00 - 23:00</p>
          <p className='p__opensans'>{t('sunday')} 11:00 - 20:00</p>
        </div>
      </div>

      <div className='footer__copyright'>
        <p className='p__opensans'>{t('right')}</p>
      </div>
    </div>
  );
}

export default Footer;
