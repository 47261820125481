import React from 'react';
import './Alacarte.css';
import { images } from '../../constants';

const Alacarte = () => {
  return (
    <div className='app__menu flex_center'>
      <img src={images.menu1} alt='Image 1' />
      <img src={images.menu2} alt='Image 2' />
      <img src={images.menu3} alt='Image 3' />
    </div>
  );
};

export default Alacarte;
