import React from 'react';
import './Lunch.css';
import { images } from '../../constants';

const Lunch = () => {
  // Get the current day in Finland's time zone
  const currentDay = new Date().toLocaleDateString('en-US', {
    weekday: 'short',
    timeZone: 'Europe/Helsinki',
  });

  // Map days of the week to image order (Monday = 0, ..., Friday = 4)
  const lunchImages = [
    images.lunch1, // Monday
    images.lunch2, // Tuesday
    images.lunch3, // Wednesday
    images.lunch4, // Thursday
    images.lunch5, // Friday
  ];

  // Determine the day index (0 = Monday, ..., 4 = Friday)
  const dayIndex = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].indexOf(currentDay);

  // Reorder the images based on the current day
  const orderedImages =
    dayIndex !== -1
      ? [
          ...lunchImages.slice(dayIndex), // Start from today
          ...lunchImages.slice(0, dayIndex), // Add earlier days at the end
        ]
      : lunchImages; // For Saturday or Sunday, show all images in order

  return (
    <div className='app__menu flex_center'>
      {orderedImages.map((image, index) => (
        <img key={index} src={image} alt={`Lunch ${index + 1}`} />
      ))}
    </div>
  );
};

export default Lunch;
