import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import logo_main from '../assets/logo_main.png';
import welcome1 from '../assets/welcome1.png';
import momo from '../assets/momo.jpeg';
import chow from '../assets/chow.jpeg';
import naan from '../assets/naan.jpeg';
import lassi from '../assets/lassi.jpeg';
import eng from '../assets/eng.png';
import fin from '../assets/fin.png';
import menu1 from '../assets/menu1.jpeg';
import menu2 from '../assets/menu2.jpeg';
import menu3 from '../assets/menu3.jpeg';
import menuall from '../assets/menuall.jpeg';
import menuall1 from '../assets/menuall1.jpeg';
import lunchall from '../assets/lunchall.jpeg';
import lunch1 from '../assets/lunch1.jpeg';
import lunch2 from '../assets/lunch2.jpeg';
import lunch3 from '../assets/lunch3.jpeg';
import lunch4 from '../assets/lunch4.jpeg';
import lunch5 from '../assets/lunch5.jpeg';
import gall1 from '../assets/gall1.jpeg';
import gall2 from '../assets/gall2.jpeg';
import gall3 from '../assets/gall3.jpeg';
import gall4 from '../assets/gall4.jpeg';
import gall5 from '../assets/gall5.jpeg';
import gall6 from '../assets/gall6.jpeg';
import gall7 from '../assets/gall7.jpeg';
import gall8 from '../assets/gall8.jpeg';
import gall9 from '../assets/gall9.jpeg';
import gall10 from '../assets/gall10.jpeg';
import gall11 from '../assets/gall11.jpeg';
import gall12 from '../assets/gall12.jpeg';
import gall13 from '../assets/gall13.jpeg';
import gall14 from '../assets/gall14.jpeg';

export default {
  gall1,
  gall2,
  gall3,
  gall4,
  gall5,
  gall6,
  gall7,
  gall8,
  gall9,
  gall10,
  gall11,
  gall12,
  gall13,
  gall14,
  menu1,
  menu2,
  menu3,
  fin,
  eng,
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  logo_main,
  welcome,
  welcome1,
  momo,
  chow,
  naan,
  lassi,
  menuall,
  menuall1,
  lunchall,
  lunch1,
  lunch2,
  lunch3,
  lunch4,
  lunch5,
};
